import { useEffect, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  doClearCreateAccountHolderMailingAddress,
  doClearPatchAccountHolderMailingAddress,
  doCreateAccountHolderMailingAddress,
  doPatchAccountHolderMailingAddress,
} from 'src/actions';
import { ErrorName, US_COUNTRY_LABEL, US_COUNTRY_STATE_LIST, US_COUNTRY_VALUE } from 'src/constants';
import { MFormSaveButton, MFormInput, MFormSelect, MFormCheckbox, ErrorResponseAlert } from 'src/lib';
import { AccountHolder } from 'src/models';
import { Spacing } from 'src/styles';
import { assertNonNullable } from 'src/utils';

import { upsertAccountHolderMailingAddressValidation } from './validations';

export const countryOptions = [{ label: US_COUNTRY_LABEL, value: US_COUNTRY_VALUE }];

interface AccountHolderMailingAddressFormValues {
  address1?: string;
  address2?: string;
  country?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  sameAsPhysical?: boolean;
}

export interface AccountHolderMailingAddressFormProps {
  accountUuid?: string;
  accountHolder?: AccountHolder;
  isPrimary: boolean;
  onCancel?: () => void;
  onSave?: () => void;
}

export const citizenshipOptions = [
  {
    label: 'U.S. Citizen',
    value: 'USA',
  },
];

export const MailingAddressForm = ({
  accountHolder,
  accountUuid,
  isPrimary,
  onCancel,
  onSave,
}: AccountHolderMailingAddressFormProps) => {
  const dispatch = useDispatch();

  const isCreateMailingAddressLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.createMailingAddress.__requested),
  );
  const succeededCreateMailingAddress = useSelector(
    (state: any) => state.accountHolders.createMailingAddress?.__succeeded,
  );
  const createError = useSelector((state: any) => state.accountHolders.createMailingAddress?.error);

  const isPatchMailingAddressLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.patchMailingAddress.__requested),
  );
  const succeededPatchMailingAddress = useSelector(
    (state: any) => state.accountHolders.patchMailingAddress?.__succeeded,
  );
  const patchError = useSelector((state: any) => state.accountHolders.patchMailingAddress?.error);

  const createRequestData = useSelector((state: any) => state.accountHolders.createMailingAddress?.data);
  const patchRequestData = useSelector((state: any) => state.accountHolders.patchMailingAddress?.data);

  const [shouldPatch, setShouldPatch] = useState<boolean>(false);

  const [isAddressPopulated, setIsAddressPopulated] = useState<boolean>(false);
  const isMailingAddressAlreadySaved = () => !isEmpty(accountHolder?.mailingAddress);

  const isPhysicalAddressAlreadySaved = () => !isEmpty(accountHolder?.physicalAddress);

  const isRequestMatched = () =>
    accountHolder?.id === createRequestData?.params?.accountHolderId ||
    accountHolder?.id === patchRequestData?.params?.accountHolderId;

  const isUpsertLoading = (isCreateMailingAddressLoading || isPatchMailingAddressLoading) && isRequestMatched();

  const _onCancel = (form: FormikProps<AccountHolderMailingAddressFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<AccountHolderMailingAddressFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    if (isMailingAddressAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [accountHolder]);

  useEffect(() => {
    if ((succeededCreateMailingAddress || succeededPatchMailingAddress) && isRequestMatched()) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededCreateMailingAddress, succeededPatchMailingAddress]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccountHolderMailingAddress());
      dispatch(doClearPatchAccountHolderMailingAddress());
    };
  }, []);

  return (
    <Formik<AccountHolderMailingAddressFormValues>
      enableReinitialize
      validateOnChange
      initialValues={
        isMailingAddressAlreadySaved()
          ? {
              address1: accountHolder?.mailingAddress?.address1,
              address2: accountHolder?.mailingAddress?.address2,
              city: accountHolder?.mailingAddress?.city,
              country: accountHolder?.mailingAddress?.country?.value ?? US_COUNTRY_VALUE,
              state: accountHolder?.mailingAddress?.state?.value,
              postalCode: accountHolder?.mailingAddress?.postalCode,
              sameAsPhysical: accountHolder?.mailingAddress?.sameAsPhysical,
            }
          : {
              address1: accountHolder?.physicalAddress?.address1,
              address2: accountHolder?.physicalAddress?.address2,
              city: accountHolder?.physicalAddress?.city,
              country: accountHolder?.physicalAddress?.country?.value ?? US_COUNTRY_VALUE,
              state: accountHolder?.physicalAddress?.state?.value,
              postalCode: accountHolder?.physicalAddress?.postalCode,
              sameAsPhysical: true,
            }
      }
      validationSchema={upsertAccountHolderMailingAddressValidation}
      onSubmit={values => {
        assertNonNullable(accountHolder, 'AccountHolder');
        assertNonNullable(accountUuid, 'accountUuid');

        if (shouldPatch) {
          dispatch(
            doPatchAccountHolderMailingAddress({
              params: {
                id: accountUuid,
                accountHolderId: accountHolder.id,
              },
              body: values,
            }),
          );

          return;
        }

        dispatch(
          doCreateAccountHolderMailingAddress({
            params: {
              id: accountUuid,
              accountHolderId: accountHolder.id,
            },
            body: values,
          }),
        );
      }}>
      {form => {
        const onPopulateMailingAddress = () => {
          if (createError?.error === ErrorName.UspsAddressMismatch) {
            const message = JSON.parse(createError?.message);
            form.setFieldValue('address1', message.address.address1);
            form.setFieldValue('address2', message.address.address2);
            form.setFieldValue('city', message.address.city);
            form.setFieldValue('state', message.address.state);
            form.setFieldValue('postalCode', message.address.postalCode);
            setIsAddressPopulated(true);
          }

          if (patchError?.error === ErrorName.UspsAddressMismatch) {
            const message = JSON.parse(patchError?.message);
            form.setFieldValue('address1', message.address.address1);
            form.setFieldValue('address2', message.address.address2);
            form.setFieldValue('city', message.address.city);
            form.setFieldValue('state', message.address.state);
            form.setFieldValue('postalCode', message.address.postalCode);
            setIsAddressPopulated(true);
          }
        };

        useEffect(() => {
          setIsAddressPopulated(false);
        }, [createError, patchError]);

        return (
          <>
            <Col span={24}>
              <MFormCheckbox
                label={
                  isPrimary
                    ? 'Mailing address is the same as my physical address'
                    : "Mailing address is the same as Joint Account Holder's physical address"
                }
                checked={form.values.sameAsPhysical}
                disabled={!shouldPatch && !isPhysicalAddressAlreadySaved()}
                onChange={async value => {
                  form.setFieldValue('sameAsPhysical', value.checked);

                  if (value.checked) {
                    form.setFieldValue('address1', accountHolder?.physicalAddress?.address1);
                    form.setFieldValue('address2', accountHolder?.physicalAddress?.address2);
                    form.setFieldValue('city', accountHolder?.physicalAddress?.city);
                    form.setFieldValue('state', accountHolder?.physicalAddress?.state?.value);
                    form.setFieldValue('postalCode', accountHolder?.physicalAddress?.postalCode);
                    form.setFieldValue('country', accountHolder?.physicalAddress?.country?.value);

                    return;
                  }

                  if (accountHolder?.mailingAddress?.sameAsPhysical) {
                    form.setFieldValue('address1', '');
                    form.setFieldValue('address2', '');
                    form.setFieldValue('city', '');
                    form.setFieldValue('state', '');
                    form.setFieldValue('postalCode', '');

                    return;
                  }

                  form.setFieldValue('address1', accountHolder?.mailingAddress?.address1);
                  form.setFieldValue('address2', accountHolder?.mailingAddress?.address2);
                  form.setFieldValue('city', accountHolder?.mailingAddress?.city);
                  form.setFieldValue('state', accountHolder?.mailingAddress?.state?.value);
                  form.setFieldValue('postalCode', accountHolder?.mailingAddress?.postalCode);
                }}
                className={Spacing.my16}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-as-physical`}
              />
            </Col>

            {!form.values.sameAsPhysical && (
              <>
                <Col span={24}>
                  <MFormInput
                    label='Address Line 1'
                    placeholder='Enter'
                    value={form.values.address1}
                    defaultValue={accountHolder?.mailingAddress?.address1}
                    error={form.errors.address1}
                    onChange={value => {
                      form.setFieldValue('address1', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-address1`}
                  />
                </Col>
                <Col span={24}>
                  <MFormInput
                    label='Address Line 2 (Opt.)'
                    placeholder='Enter'
                    value={form.values.address2}
                    defaultValue={accountHolder?.mailingAddress?.address2}
                    error={form.errors.address2}
                    onChange={value => {
                      form.setFieldValue('address2', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-address2`}
                  />
                </Col>
                <Col span={24}>
                  <MFormSelect
                    label='Country'
                    placeholder='Select'
                    defaultValue={accountHolder?.mailingAddress?.country?.value ?? US_COUNTRY_VALUE}
                    options={countryOptions}
                    error={form.errors.country}
                    onChange={value => {
                      form.setFieldValue('country', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-country`}
                  />
                </Col>
                <Col span={24}>
                  <MFormInput
                    label='City'
                    placeholder='Enter'
                    value={form.values.city}
                    defaultValue={accountHolder?.mailingAddress?.city}
                    error={form.errors.city}
                    onChange={value => {
                      form.setFieldValue('city', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-city`}
                  />
                </Col>
                <Col span={24}>
                  <MFormSelect
                    label='State'
                    placeholder='Select'
                    defaultValue={accountHolder?.mailingAddress?.state?.value}
                    value={form.values.state}
                    options={US_COUNTRY_STATE_LIST}
                    error={form.errors.state}
                    onChange={value => {
                      form.setFieldValue('state', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-state`}
                  />
                </Col>
                <Col span={24}>
                  <MFormInput
                    label='Postal Code'
                    placeholder='Enter'
                    value={form.values.postalCode}
                    defaultValue={accountHolder?.mailingAddress?.postalCode}
                    error={form.errors.postalCode}
                    onChange={value => {
                      form.setFieldValue('postalCode', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-postal-code`}
                  />
                </Col>
              </>
            )}
            {!isAddressPopulated && isRequestMatched() && (
              <Col span={24}>
                <ErrorResponseAlert
                  error={createError ?? patchError}
                  name='mailing'
                  onClick={onPopulateMailingAddress}
                />
              </Col>
            )}
            <Col span={24}>
              <MFormSaveButton<AccountHolderMailingAddressFormValues>
                loading={isUpsertLoading}
                onCancel={_onCancel}
                onSave={_onSave}
                isEditMode={shouldPatch}
                form={{
                  ...form,
                  isValid:
                    form.values.sameAsPhysical && !isPhysicalAddressAlreadySaved()
                      ? false
                      : form.values.sameAsPhysical && isPhysicalAddressAlreadySaved()
                      ? true
                      : form.isValid,
                }} // NOTE: Formik does not revalidate after setFieldValue()
                testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-address`}
              />
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
