/**
 * Effects action states
 */
export const stateTypes = {
  REQUESTED: 'Requested',
  SUCCEEDED: 'Succeeded',
  FAILED: 'Failed',
  CLEARED: 'Cleared',
};

/** Authentication actions */
export const AUTH_STATE = 'Authentication';
export const SET_AUTHENTICATION = `${AUTH_STATE}/SET_AUTHENTICATION`;

/**
 * User actions
 */
export const USER_STATE = 'User';
export const SIGN_UP = `${USER_STATE}/SIGN_UP`;
export const PATCH_USER_ADDRESS = `${USER_STATE}/PATCH_USER_ADDRESS`;
export const TOGGLE_MFA_CONFIG = `${USER_STATE}/TOGGLE_MFA_CONFIG`;
export const LOGIN = `${USER_STATE}/Login`;
export const LOGOUT = `${USER_STATE}/LOGOUT`;
export const GET_AUTHENTICATED_USER = `${USER_STATE}/GET_AUTHENTICATED_USER`;
export const UPDATE_AUTHENTICATED_USER = `${AUTH_STATE}/UPDATE_AUTHENTICATED_USER`;
export const CONFIRM_MFA = `${USER_STATE}/ConfirmMfaCode`;
export const RESEND_MFA_CODE = `${USER_STATE}/ResendMfaCode`;
export const FORGOT_PASSWORD = `${USER_STATE}/ForgotPassword`;
export const RESET_PASSWORD = `${USER_STATE}/ResetPassword`;
export const EMAIL_CHANGE = `${USER_STATE}/Email/Change`;
export const EMAIL_CONFIRM = `${USER_STATE}/Email/Confirm`;
export const UPDATE_PHONE_NUMBER = `${USER_STATE}/UPDATE_PHONE_NUMBER`;
/**
 * Account actions
 */

// Account
export const CREATE_ACCOUNT = `CREATE_ACCOUNT`;
export const GET_ACCOUNT = `GET_ACCOUNT`;
export const PATCH_ACCOUNT = 'PATCH_ACCOUNT';
export const PATCH_JOINT_ACCOUNT_INTEREST = 'PATCH_JOINT_ACCOUNT_INTEREST';
export const PATCH_ACCOUNT_BENEFICIARIES = 'PATCH_BENEFICIARIES';
export const ACCOUNTS_GET_BALANCE = `ACCOUNTS_GET_BALANCE`;
export const GET_ACCOUNT_WITHDRAWAL_FUNDS_BALANCE = 'GET_ACCOUNT_WITHDRAWAL_FUNDS_BALANCE';
export const REFRESH_ACCOUNT_BALANCE = `REFRESH_ACCOUNT_BALANCE`;
export const SET_ACCOUNT_VALUE = `SET_ACCOUNT_VALUE`;
export const SUBMIT_ACCOUNT = `SUBMIT_ACCOUNT`;
export const DOWNLOAD_ACCOUNT_SIGNATURE = `DOWNLOAD_ACCOUNT_SIGNATURE`;
export const SWITCH_ACCOUNT = 'SWITCH_ACCOUNT';

// Account Holder
export const PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION = `PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION`;
export const CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS = `CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS`;
export const PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS = `PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS`;
export const CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS = `CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS`;
export const PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS = `PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS`;
export const CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT = `CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT`;
export const PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT = `PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT`;
export const DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT = `DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT`;
export const CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION = `CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION`;
export const PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION = `PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION`;
export const CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT = `CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT`;
export const PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT = `PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT`;
export const CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS = `CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS`;
export const PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS = `PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS`;
export const CREATE_ACCOUNT_HOLDER_DISCLOSURES = `CREATE_ACCOUNT_HOLDER_DISCLOSURES`;
export const PATCH_ACCOUNT_HOLDER_DISCLOSURES = `PATCH_ACCOUNT_HOLDER_DISCLOSURES`;

/**
 * Cashiering actions
 */
export const CASHIERING_GET_PLAID_LIK_TOKEN = 'Cashiering/Plaid/PlaidLinkToken/Get';
// ACH Relationships
export const CASHIERING_ACH_RELATIONSHIPS = 'Cashiering/ACH/ACHRelationships';
export const CASHIERING_GET_ACH_RELATIONSHIPS = `${CASHIERING_ACH_RELATIONSHIPS}/Get`;
export const CASHIERING_CREATE_ACH_RELATIONSHIP = `${CASHIERING_ACH_RELATIONSHIPS}/Create`;
export const CASHIERING_UNLINK_ACH_RELATIONSHIP = `${CASHIERING_ACH_RELATIONSHIPS}/UnlinkBankAccount`;
export const CASHIERING_EDIT_NICKNAME = `CASHIERING_EDIT_NICKNAME`;
// Deposits
export const CASHIERING_DEPOSIT_FUNDS = 'Cashiering/ACH/DepositFunds';
// Withdraws
export const CASHIERING_WITHDRAW_FUNDS = 'Cashiering/ACH/WithdrawFunds';
// Transfers
export const CASHIERING_TRANSFERS = 'Cashiering/Transfers';
export const CASHIERING_GET_TRANSFERS = `${CASHIERING_TRANSFERS}/Get`;
export const CASHIERING_CANCEL_DEPOSIT = `${CASHIERING_TRANSFERS}/Deposit/Cancel`;
export const CASHIERING_CANCEL_WITHDRAW = `${CASHIERING_TRANSFERS}/Withdraw/Cancel`;
export const CASHIERING_GET_TRANSACTION_DETAILS = `${CASHIERING_TRANSFERS}/TransactionDetails/Get`;

// Summary
export const SUMMARY = 'Summary';
export const GET_SUMMARY_CASH_AVAILABLE = `${SUMMARY}/CashAvailable/Get`;
export const GET_SUMMARY_PENDING_TRANSACTIONS = `${SUMMARY}/PendingTransactions/Get`;

/**
 * Offers actions
 */
export const OFFER_GET_OFFER_LIST = 'Offer/OfferList/Get';
export const OFFER_GET_OFFER_DETAILS = 'Offer/OfferDetails/Get';

/**
 * Offer Options
 */

export const GET_ALL_OFFER_OPTIONS = 'OfferOptions/GET_ALL_OFFER_OPTIONS';

/**
 * Offer Watches actions
 */
export const OFFER_WATCHES = 'OfferWatches';
export const OFFER_WATCHES_GET_LIST = `${OFFER_WATCHES}/Get/List`;
export const OFFER_WATCHES_CREATE = `${OFFER_WATCHES}/Post/Create`;
export const OFFER_WATCHES_DELETE = `${OFFER_WATCHES}/Post/Delete`;

/**
 * Investigations actions
 */
export const GET_INVESTIGATIONS = `GET_INVESTIGATION_LIST`;

/**
 * Configs
 */
export const CONFIGS = 'Configs';
export const GET_ALL_CONFIGS = `${CONFIGS}/All/Get`;

/**
 * Quote Media
 */
export const QUOTE_MEDIA = 'QuoteMedia';
export const GET_QUOTE_MEDIA = `${QUOTE_MEDIA}/Get`;
export const GET_ENHANCED_QUOTE_MEDIA = `${QUOTE_MEDIA}/Get/Enhanced`;
export const GET_CHART_QUOTE_MEDIA = `${QUOTE_MEDIA}/Get/Chart`;

/**
 * Orders
 */
export const ORDERS = 'Orders';
export const GET_ORDERS = `${ORDERS}/Get`;
export const GET_ORDERS_ID = `${ORDERS}/Get/ID`;
export const GET_ORDERS_POSITIONS = `${ORDERS}/Get/Positions`;
export const REFRESH_ORDER_POSITIONS = `${ORDERS}/RefreshOrderPositions`;
export const CREATE_ORDERS_REVIEW = `${ORDERS}/Create/Review`;
export const CREATE_ORDERS = `${ORDERS}/Create`;
export const UPDATE_ORDERS = `${ORDERS}/Update`;
export const UPDATE_ORDER_FROM_EVENT = `${ORDERS}/UPDATE_FORM_EVENT`;

/**
 * Offer Orders
 */
export const OFFER_ORDERS = 'OfferOrders';
export const GET_OFFER_ORDERS = `${OFFER_ORDERS}/Get`;
export const GET_OFFER_ORDER_DETAILS = `${OFFER_ORDERS}/GET_OFFER_ORDER_DETAILS`;
export const CREATE_OFFER_ORDERS = `${OFFER_ORDERS}/Create`;
export const UPDATE_OFFER_ORDER = `${OFFER_ORDERS}/UPDATE_OFFER_ORDER`;
export const DELETE_OFFER_ORDER = `${OFFER_ORDERS}/DELETE_OFFER_ORDER`;
export const ADD_OFFER_ORDER_INTENT = `${OFFER_ORDERS}/Intent/Add`;
export const REMOVE_OFFER_ORDER_INTENT = `${OFFER_ORDERS}/Intent/Remove`;
export const CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT = `${OFFER_ORDERS}/CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT`;
export const UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT = `${OFFER_ORDERS}/UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT`;
export const SUBMIT_CARD_PAYMENT = `${OFFER_ORDERS}/SUBMIT_CARD_PAYMENT`;
export const CONFIRM_CARD_PAYMENT = `${OFFER_ORDERS}/CONFIRM_CARD_PAYMENT`;

/**
 * Toast Messages
 */
export const TOAST_MESSAGES = 'ToastMessages';
export const TOAST_MESSAGES_ADD = `${TOAST_MESSAGES}/Add`;
export const TOAST_MESSAGES_REMOVE = `${TOAST_MESSAGES}/Remove`;
export const CLEAR_TOAST_MESSAGES = `${TOAST_MESSAGES}/Clear`;

/**
 * App
 */
export const APP = 'App';
export const ADD_REDIRECT_TO_OFFER_ORDER = 'ADD_REDIRECT_TO_OFFER_ORDER';
export const REMOVE_REDIRECT_TO_OFFER_ORDER = 'REMOVE_REDIRECT_TO_OFFER_ORDER';
export const ADD_REDIRECT_TO_OFFER = 'ADD_REDIRECT_TO_OFFER';
export const REMOVE_REDIRECT_TO_OFFER = 'REMOVE_REDIRECT_TO_OFFER';
export const APP_REDIRECT_TO_EMAIL_CONFIRM_ADD = `${APP}/RedirectTo/EmailConfirm/Add`;
export const APP_REDIRECT_TO_EMAIL_CONFIRM_REMOVE = `${APP}/RedirectTo/EmailConfirm/Remove`;
export const UPDATE_ONBOARD_STEP = `${APP}/UPDATE_ONBOARD_STEP`;
export const ADD_OFFER_INVEST_REFERRER = `${APP}/ADD_OFFER_INVEST_REFERRER`;
export const REMOVE_OFFER_INVEST_REFERRER = `${APP}/REMOVE_OFFER_INVEST_REFERRER`;

/**
 * Statements
 */
export const GET_TRADE_CONFIRMATION_LIST = 'Statements/GET_TRADE_CONFIRMATION_LIST';
export const DOWNLOAD_TRADE_CONFIRMATION = 'Statements/DOWNLOAD_TRADE_CONFIRMATION';
export const REVOKE_TRADE_CONFIRMATION_DOWNLOADS = 'Statements/REVOKE_TRADE_CONFIRMATION_DOWNLOADS';
export const GET_MONTHLY_STATEMENT_LIST = 'Statements/GET_MONTHLY_STATEMENT_LIST';
export const DOWNLOAD_MONTHLY_STATEMENT = 'Statements/DOWNLOAD_MONTHLY_STATEMENT';
export const REVOKE_MONTHLY_STATEMENT_DOWNLOADS = 'Statements/REVOKE_MONTHLY_STATEMENT_DOWNLOADS';
export const GET_TAX_DOCUMENT_LIST = 'Statements/GET_TAX_DOCUMENT_LIST';
export const DOWNLOAD_TAX_DOCUMENT = 'Statements/DOWNLOAD_TAX_DOCUMENT';
export const REVOKE_TAX_DOCUMENT_DOWNLOADS = 'Statements/REVOKE_TAX_DOCUMENT_DOWNLOADS';
