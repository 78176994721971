import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ErrorName } from 'src/constants';
import { Spacing } from 'src/styles';
import {
  isUnauthorizedResponseError,
  renderAddressMismatchErrorMessage,
  renderAddressNotFoundErrorMessage,
  renderNetworkErrorMessage,
  renderUnknownErrorMessage,
} from 'src/utils';

import { MAlert } from '../Miscellaneous/MAlert/MAlert';

interface ErrorResponseAlertProps {
  name: string;
  error?: any;
  onClick: () => void;
}

export const ErrorResponseAlert = ({ error, name, onClick }: ErrorResponseAlertProps) => {
  const renderDescription = () => {
    if (error?.error === ErrorName.UspsAddressMismatch) {
      const message = JSON.parse(error.message);

      return renderAddressMismatchErrorMessage({
        address: message.address,
        onClick,
        section: name,
      });
    }

    if (error?.error === ErrorName.UspsAddressNotFound) {
      return renderAddressNotFoundErrorMessage();
    }

    if (error?.error === ErrorName.Network) {
      return renderNetworkErrorMessage();
    }

    return renderUnknownErrorMessage(error);
  };

  if (!error) {
    return null;
  }

  if (isUnauthorizedResponseError(error)) {
    return null;
  }

  return (
    <MAlert
      type='error'
      description={renderDescription()}
      showIcon
      icon={<ExclamationCircleOutlined />}
      closable={false}
      className={Spacing.my16}
    />
  );
};
