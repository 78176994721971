import {
  OfferOrderCardPaymentDto,
  OfferOrderPaymentTypeDto,
  OfferOrderStatusDto,
  OfferOrderCardPaymentIntentStatusDto,
  OfferOrderRefundDto,
  OfferOrderRefundStatusDto,
  OfferOrderRefundReasonDto,
  OfferOrderConditionalDto,
  OfferOrderRefundMechanismDto,
  OfferOrderSecurityDeliveryOptionDto,
  OfferCardPaymentAccountStatusDto,
  OfferExternalCheckInstructionsDto,
  OfferExternalWireInstructionsDto,
  OfferOrderExternalDetailsDto,
  OfferOrderPaymentInstructionsDto,
  OfferOrderAnalyticsDto,
  OfferOrderRetirementAccountFundingTypeDto,
} from 'src/dtos';
import { Maybe } from 'src/utils';

import { AccountType, RetirementAccountType } from './accounts.models';

export interface OfferOrderIntent {
  account: {
    id: string;
    accountId: number;
    type: AccountType;
    retirementAccountType?: RetirementAccountType;
  };
  quantity: number;
  price: number;
  totalInvestment: number;
  isExternal: boolean;
  isConditional: boolean;
  cashAvailable: number;
  isNotRestrictedPerson: boolean;
  areDocumentsReviewed: boolean;
  offerId: string;
  minSharePrice?: number;
  maxSharePrice?: number;
  allowAchTransfers: boolean;
  allowWireTransfers: boolean;
  allowCheckTransfers: boolean;
  allowCardPayments: boolean;
  allowAcatTransfers: boolean;
  allowIraContributionWireTransfers: boolean;
  allowIraContributionCheckTransfers: boolean;
  cardPaymentAccountExternalId?: string;
  cardPaymentAccountStatus?: OfferCardPaymentAccountStatusDto;
  cardPaymentIntentId?: string;
  cardPaymentClientSecret?: string;
  cardPaymentIntentTotalCost?: number;
  isAccreditedInvestor?: boolean;
  paymentType?: OfferOrderPaymentType;
  achDeposit?: {
    amount?: string;
    relationshipId?: string;
  };
  hasManySecurityDeliveryOptions: boolean;
  securityDeliveryOption?: OfferOrderSecurityDeliveryOptionDto;
  wireInstructions?: OfferExternalWireInstructionsDto;
  checkInstructions?: OfferExternalCheckInstructionsDto;
  retirementAccountFundingType?: OfferOrderRetirementAccountFundingTypeDto;
}

export enum OfferOrderPaymentTypeLabel {
  Ach = 'ACH (Bank-to-Bank)',
  Wire = 'Wire transfer',
  Check = 'Check transfer',
  Card = 'Credit card',
  Acat = 'ACAT transfer',
}

export class OfferOrderPaymentType {
  private _value: OfferOrderPaymentTypeDto | undefined;

  constructor(dto?: OfferOrderPaymentTypeDto) {
    this._value = dto;
  }

  get value(): OfferOrderPaymentTypeDto | undefined {
    return this._value;
  }

  get isCard(): boolean {
    return this._value === OfferOrderPaymentTypeDto.Card;
  }

  get isAch(): boolean {
    return this._value === OfferOrderPaymentTypeDto.Ach;
  }

  get isWire(): boolean {
    return this._value === OfferOrderPaymentTypeDto.Wire;
  }

  get isCheck(): boolean {
    return this._value === OfferOrderPaymentTypeDto.Check;
  }

  get isAcat(): boolean {
    return this._value === OfferOrderPaymentTypeDto.Acat;
  }

  get label(): Maybe<OfferOrderPaymentTypeLabel> {
    switch (this._value) {
      case OfferOrderPaymentTypeDto.Ach:
        return OfferOrderPaymentTypeLabel.Ach;
      case OfferOrderPaymentTypeDto.Wire:
        return OfferOrderPaymentTypeLabel.Wire;
      case OfferOrderPaymentTypeDto.Check:
        return OfferOrderPaymentTypeLabel.Check;
      case OfferOrderPaymentTypeDto.Card:
        return OfferOrderPaymentTypeLabel.Card;
      case OfferOrderPaymentTypeDto.Acat:
        return OfferOrderPaymentTypeLabel.Acat;
    }
  }
}

export class OfferOrderCardPaymentIntentStatus {
  private _value: OfferOrderCardPaymentIntentStatusDto | 'Refunded' | 'PartiallyRefunded';

  constructor({
    status,
    totalCost,
    amountRefunded,
  }: {
    status: OfferOrderCardPaymentIntentStatusDto;
    totalCost: number;
    amountRefunded: number;
  }) {
    this._value = this.findValue({ status, totalCost, amountRefunded });
  }

  private findValue({
    status,
    totalCost,
    amountRefunded,
  }: {
    status: OfferOrderCardPaymentIntentStatusDto;
    totalCost: number;
    amountRefunded: number;
  }): OfferOrderCardPaymentIntentStatusDto | 'Refunded' | 'PartiallyRefunded' {
    if (amountRefunded > 0 && amountRefunded < totalCost) {
      return 'PartiallyRefunded';
    }

    if (amountRefunded === totalCost) {
      return 'Refunded';
    }

    return status;
  }

  get value(): OfferOrderCardPaymentIntentStatusDto | 'Refunded' | 'PartiallyRefunded' {
    return this._value;
  }

  get label(): string {
    switch (this._value) {
      case 'PartiallyRefunded':
        return 'Partially Refunded';
      case 'Refunded':
        return 'Refunded';
      case OfferOrderCardPaymentIntentStatusDto.Canceled:
        return 'Canceled';
      case OfferOrderCardPaymentIntentStatusDto.Processing:
        return 'Processing';
      case OfferOrderCardPaymentIntentStatusDto.RequiresAction:
        return 'Requires Action';
      case OfferOrderCardPaymentIntentStatusDto.RequiresCapture:
        return 'Requires Capture';
      case OfferOrderCardPaymentIntentStatusDto.RequiresConfirmation:
        return 'Requires Confirmation';
      case OfferOrderCardPaymentIntentStatusDto.RequiresPaymentMethod:
        return 'Requires Payment Method';
      case OfferOrderCardPaymentIntentStatusDto.Succeeded:
        return 'Succeeded';
      default:
        return 'NA';
    }
  }
}

export interface OfferOrderCardPayment extends Omit<OfferOrderCardPaymentDto, 'intentStatus'> {
  intentStatus: OfferOrderCardPaymentIntentStatus;
}

export class OfferOrderRefundStatus {
  private _value?: OfferOrderRefundStatusDto;
  private _label?: string;

  constructor(dto?: OfferOrderRefundStatusDto) {
    this._value = dto;
    this._label = this.findLabel(dto);
  }

  private findLabel(dto?: OfferOrderRefundStatusDto) {
    switch (dto) {
      case OfferOrderRefundStatusDto.Canceled:
        return 'Canceled';
      case OfferOrderRefundStatusDto.Failed:
        return 'Failed';
      case OfferOrderRefundStatusDto.Pending:
        return 'Pending';
      case OfferOrderRefundStatusDto.RequiresAction:
        return 'Requires Action';
      case OfferOrderRefundStatusDto.Succeeded:
        return 'Succeeded';
      default:
        return dto;
    }
  }

  get value(): OfferOrderRefundStatusDto | 'NA' {
    return this._value ?? 'NA';
  }

  get isPending() {
    return (
      this._value === OfferOrderRefundStatusDto.Pending || this._value === OfferOrderRefundStatusDto.RequiresAction
    );
  }

  get hasFailed() {
    return this._value === OfferOrderRefundStatusDto.Failed;
  }

  get hasSucceeded() {
    return this._value === OfferOrderRefundStatusDto.Succeeded;
  }

  get label(): string {
    return this._label ?? 'NA';
  }
}

export class OfferOrderRefundReason {
  private _value?: OfferOrderRefundReasonDto;
  private _label?: string;

  constructor(dto?: OfferOrderRefundReasonDto) {
    this._value = dto;
    this._label = this.findLabel(dto);
  }

  private findLabel(dto?: OfferOrderRefundReasonDto) {
    switch (dto) {
      case OfferOrderRefundReasonDto.Duplicate:
        return 'Duplicate';
      case OfferOrderRefundReasonDto.Fraudulent:
        return 'Fraudulent';
      case OfferOrderRefundReasonDto.RequestedByCustomer:
        return 'Requested by Customer';
      default:
        return dto;
    }
  }

  get value(): OfferOrderRefundReasonDto | 'NA' {
    return this._value ?? 'NA';
  }

  get label(): string {
    return this._label ?? 'NA';
  }
}

export enum OfferOrderRefundLabel {
  Ach = 'ACH',
  Wire = 'Wire transfer',
  Check = 'Check transfer',
  Card = 'Credit card',
}

export class OfferOrderRefundMechanism {
  private _value: OfferOrderRefundMechanismDto;
  private _label: OfferOrderRefundLabel;

  constructor(dto: OfferOrderRefundMechanismDto) {
    this._value = dto;
    this._label = this.findLabel(dto);
  }

  private findLabel(dto: OfferOrderRefundMechanismDto) {
    if (dto === OfferOrderRefundMechanismDto.Ach) {
      return OfferOrderRefundLabel.Ach;
    }

    if (dto === OfferOrderRefundMechanismDto.Wire) {
      return OfferOrderRefundLabel.Wire;
    }

    if (dto === OfferOrderRefundMechanismDto.Check) {
      return OfferOrderRefundLabel.Check;
    }

    return OfferOrderRefundLabel.Card;
  }

  get isCard() {
    return this._value === OfferOrderRefundMechanismDto.Card;
  }

  get isAch() {
    return this._value === OfferOrderRefundMechanismDto.Ach;
  }

  get isCheck() {
    return this._value === OfferOrderRefundMechanismDto.Check;
  }

  get isWire() {
    return this._value === OfferOrderRefundMechanismDto.Wire;
  }

  get value(): OfferOrderRefundMechanismDto {
    return this._value;
  }

  get label(): string {
    return this._label;
  }
}

export interface OfferOrderRefund extends Omit<OfferOrderRefundDto, 'status' | 'reason' | 'mechanism'> {
  status: OfferOrderRefundStatus;
  reason: OfferOrderRefundReason;
  mechanism: OfferOrderRefundMechanism;
}

export enum OfferOrderStatusLabel {
  PendingFunds = 'PENDING FUNDS',
  PendingAction = 'PENDING ACTION NEEDED',
  PendingOfferClose = 'PENDING OFFERING CLOSING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Complete = 'COMPLETED',
  PendingFirmCancellation = 'PENDING CANCELLATION',
  Cancelled = 'CANCELLED',
  Deleted = 'DELETED',
}

export class OfferOrderStatus {
  private _value: OfferOrderStatusDto;
  private _label: OfferOrderStatusLabel;

  constructor(state: OfferOrderStatusDto) {
    this._value = state;
    this._label = this.findLabel();
  }

  private findLabel(): OfferOrderStatusLabel {
    switch (this._value) {
      case OfferOrderStatusDto.PendingFunds:
        return OfferOrderStatusLabel.PendingFunds;
      case OfferOrderStatusDto.PendingAction:
        return OfferOrderStatusLabel.PendingAction;
      case OfferOrderStatusDto.PendingOfferClose:
        return OfferOrderStatusLabel.PendingOfferClose;
      case OfferOrderStatusDto.Approved:
        return OfferOrderStatusLabel.Approved;
      case OfferOrderStatusDto.Rejected:
        return OfferOrderStatusLabel.Rejected;
      case OfferOrderStatusDto.Complete:
        return OfferOrderStatusLabel.Complete;
      case OfferOrderStatusDto.Cancelled:
        return OfferOrderStatusLabel.Cancelled;
      case OfferOrderStatusDto.Deleted:
        return OfferOrderStatusLabel.Deleted;
      case OfferOrderStatusDto.PendingFirmCancellation:
        return OfferOrderStatusLabel.PendingFirmCancellation;
    }
  }

  get value(): OfferOrderStatusDto {
    return this._value;
  }

  get label(): OfferOrderStatusLabel {
    return this._label;
  }

  get isPendingFunds(): boolean {
    return this._value === OfferOrderStatusDto.PendingFunds;
  }

  get isPendingAction(): boolean {
    return this._value === OfferOrderStatusDto.PendingAction;
  }

  get isPendingOfferClose(): boolean {
    return this._value === OfferOrderStatusDto.PendingOfferClose;
  }

  get isApproved() {
    return this._value === OfferOrderStatusDto.Approved;
  }

  get isPendingFirmCancellation() {
    return this._value === OfferOrderStatusDto.PendingFirmCancellation;
  }

  get isCancelled() {
    return this._value === OfferOrderStatusDto.Cancelled;
  }

  get isRejected() {
    return this._value === OfferOrderStatusDto.Rejected;
  }

  get isCompleted() {
    return this._value === OfferOrderStatusDto.Complete;
  }
}

export enum OfferOrderRetirementAccountFundingTypeLabel {
  IraContribution = 'IRA Contribution',
}

export class OfferOrderRetirementAccountFundingType {
  private _value: OfferOrderRetirementAccountFundingTypeDto;
  private _label: OfferOrderRetirementAccountFundingTypeLabel;

  constructor(dto: OfferOrderRetirementAccountFundingTypeDto) {
    this._value = dto;
    this._label = this.findLabel();
  }

  private findLabel(): OfferOrderRetirementAccountFundingTypeLabel {
    return OfferOrderRetirementAccountFundingTypeLabel.IraContribution;
  }

  get value(): OfferOrderRetirementAccountFundingTypeDto {
    return this._value;
  }

  get label(): string {
    return this._label;
  }
}

export interface OfferOrder {
  key: React.Key;
  id: string;
  accountId: number;
  offerName: string;
  offerId: string;
  offerCloseDate?: string;
  quantity: number;
  purchasePrice: number;
  value: number;
  status: OfferOrderStatus;
  isExternal: boolean;
  isAccreditedInvestor?: boolean;
  paymentType: OfferOrderPaymentType;
  amountRefunded: number;
  refunds: OfferOrderRefund[];
  cardPayment?: OfferOrderCardPayment;
  conditional?: OfferOrderConditionalDto;
  externalDetails?: OfferOrderExternalDetailsDto;
  retirementAccountFundingType?: OfferOrderRetirementAccountFundingType;
  localeDate: string;
  createdAt: string;
  updatedAt?: string;
}

export interface OfferOrderDetails extends OfferOrder {
  paymentInstructions?: OfferOrderPaymentInstructionsDto;
  analytics: OfferOrderAnalyticsDto;
}
