import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { MAIN_PATH } from '../../pages/Main';

import { OFFER_ORDERS_PATH } from './NewOfferOrderGuard';

export const CanActivateSummaryGuard = () => {
  const redirectToOfferOrder: string | null = useSelector((state: any) => state.app.redirectToOfferOrder);
  const redirectToOffer: string[] = useSelector((state: any) => state.app.redirectToOffer);
  const redirectToEmailConfirm: { confirm: string; updateId: number } | null = useSelector(
    (state: any) => state.app.redirectToEmailConfirm,
  );

  if (redirectToOfferOrder) {
    return <Navigate to={`/${MAIN_PATH}/${OFFER_ORDERS_PATH}/${redirectToOfferOrder}/orders/new`} />;
  }

  if (redirectToEmailConfirm) {
    const { confirm, updateId } = redirectToEmailConfirm;

    return <Navigate to={`/email-confirm?confirm=${confirm}&id=${updateId}`} />;
  }

  if (redirectToOffer) {
    return <Navigate to={`/offers/${redirectToOffer}`} />;
  }

  return <Outlet />;
};
