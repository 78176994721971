import { useAccountSelector } from 'src/hooks';

export const RetirementAccountContributionFormLink = () => {
  const { account } = useAccountSelector();

  const getFormLink = () => {
    if (account?.retirementAccountType?.isSimple) {
      return 'https://legacy.tradingblock.com/Admin/Forms/Apex/Cashiering/SIMPLE_IRA_Deposit_Slip.pdf';
    }

    return 'https://legacy.tradingblock.com/Admin/Forms/Apex/Cashiering/IRA_Contribution_Form.pdf';
  };

  return (
    <a href={getFormLink()} target='_blank' rel='noreferrer'>
      IRA Contribution Form
    </a>
  );
};
