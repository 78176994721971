import { includes } from 'lodash';
import { RetirementAccountTypeDto } from 'src/dtos';
import { useAccountSelector } from 'src/hooks';

export const RetirementAccountDistributionFormLink = () => {
  const { account } = useAccountSelector();

  if (
    includes(
      [RetirementAccountTypeDto.Roth, RetirementAccountTypeDto.Beneficiary, RetirementAccountTypeDto.RothBeneficiary],
      account?.retirementAccountType?.value,
    )
  ) {
    return (
      <a
        href='https://legacy.tradingblock.com/Admin/Forms/Apex/Cashiering/Roth_Distribution_Form.pdf'
        target='_blank'
        rel='noreferrer'>
        Roth IRA Distribution Form
      </a>
    );
  }

  return (
    <a
      href='https://legacy.tradingblock.com/Docs/Forms/Apex/Cashiering/IRA_Distribution_Form.pdf'
      target='_blank'
      rel='noreferrer'>
      IRA Distribution Form
    </a>
  );
};
