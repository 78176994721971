import { useEffect } from 'react';

import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { addOfferOrderIntent } from 'src/actions';
import { OfferOrderPaymentTypeDto } from 'src/dtos';
import { useFindMinimalDepositAmountForInvestment } from 'src/hooks';
import { MFormCurrencyField, MFormSelect, RetirementAccountContributionFormLink } from 'src/lib';
import CardComponent from 'src/lib/Miscellaneous/Card';
import { OfferOrderIntent, OfferOrderPaymentType } from 'src/models';
import { Heading } from 'src/styles';

import * as Styles from './WirePayment.styles';

interface IraContributionPaymentProps {
  isValid: (value: boolean) => void;
  defaultPaymentType?: OfferOrderPaymentTypeDto;
}

export const IraContributionPayment = ({ isValid, defaultPaymentType }: IraContributionPaymentProps) => {
  const dispatch = useDispatch();
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);

  const minimalDepositAmount = useFindMinimalDepositAmountForInvestment();

  const getTransferMethodOptionList = () => {
    let options = [];

    if (offerOrderIntent.allowIraContributionCheckTransfers) {
      options.push({ label: 'Check Transfer', value: OfferOrderPaymentTypeDto.Check });
    }

    if (offerOrderIntent.allowIraContributionWireTransfers) {
      options.push({ label: 'Wire Transfer', value: OfferOrderPaymentTypeDto.Wire });
    }

    return options;
  };

  useEffect(() => {
    isValid(!isEmpty(getTransferMethodOptionList()));
  }, []);

  return (
    <div className={Styles.container} data-testid={'confirm-order-modal-wire'}>
      <CardComponent className={Styles.card}>
        <Row>
          <Col span={24} className={Heading.text1}>
            <p>
              Once you Place Order, My IPO will email the <RetirementAccountContributionFormLink /> and funding
              instructions for you to complete, sign and return with your payment type.
            </p>
            <p>
              Funds being contributed into retirement accounts may entail tax consequences. Contributions are limited
              and withdrawals before age 59 1/2 may be subject to a penalty tax.
            </p>
            <p>
              All IRA contributions count toward your annual limit. If you made contributions to another external IRA,
              you&apos;ll want to factor in those amounts when contributing to your My IPO IRA. contribution limits can
              change annually. Visit the{' '}
              <a
                href='https://www.irs.gov/retirement-plans/retirement-plans-faqs-regarding-iras-distributions-withdrawals'
                target='_blank'
                rel='noreferrer'>
                IRS website
              </a>{' '}
              and consult a tax advisor for your specific situation.
            </p>
          </Col>
          <Col span={24}>
            <MFormCurrencyField
              label='Transfer Amount'
              value={minimalDepositAmount}
              testId={'ira-contribution-transfer-amount'}
            />
          </Col>
          <Col span={24}>
            <MFormSelect
              label='Transfer method'
              defaultValue={defaultPaymentType}
              options={getTransferMethodOptionList()}
              onChange={value => {
                dispatch(
                  addOfferOrderIntent({
                    ...offerOrderIntent,
                    paymentType: new OfferOrderPaymentType(value as OfferOrderPaymentTypeDto),
                  }),
                );
              }}
              weight='light'
            />
          </Col>
        </Row>
      </CardComponent>
    </div>
  );
};
