import { combineReducers } from 'redux';

import * as State from '../actions/types';
import { Type } from '../actions/utils';

import initialState from './initial';

export const redirectToOffer = (state: any = initialState.app.redirectToOffer, action: any) => {
  switch (action.type) {
    case Type.ADD_REDIRECT_TO_OFFER:
      return action.payload;
    case Type.REMOVE_REDIRECT_TO_OFFER:
      return null;
    default:
      return state;
  }
};

export const redirectToOfferOrder = (state: any = initialState.app.redirectToOfferOrder, action: any) => {
  switch (action.type) {
    case Type.ADD_REDIRECT_TO_OFFER_ORDER:
      return action.payload;
    case Type.REMOVE_REDIRECT_TO_OFFER_ORDER:
      return null;
    default:
      return state;
  }
};

export const redirectToEmailConfirm = (state: any = initialState.app.redirectToEmailConfirm, action: any) => {
  switch (action.type) {
    case Type.APP_REDIRECT_TO_EMAIL_CONFIRM_REMOVE:
      return null;
    case Type.APP_REDIRECT_TO_EMAIL_CONFIRM_ADD:
      return action.payload;
    default:
      return state;
  }
};

export const onboardStep = (state: any = initialState.app.onboardStep, action: any) => {
  switch (action.type) {
    case Type.UPDATE_ONBOARD_STEP:
      return action.payload;
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.app.onboardStep;
    default:
      return state;
  }
};

export const offerInvestReferrer = (state: any = initialState.app.offerInvestReferrer, action: any) => {
  switch (action.type) {
    case Type.ADD_OFFER_INVEST_REFERRER:
      return action.payload;
    case Type.REMOVE_OFFER_INVEST_REFERRER:
      return initialState.app.offerInvestReferrer;
    default:
      return state;
  }
};

export default combineReducers({
  redirectToOffer,
  redirectToOfferOrder,
  redirectToEmailConfirm,
  onboardStep,
  offerInvestReferrer,
});
