import { cx } from '@emotion/css';
import { Col, Row } from 'antd';
import { useAccountSelector } from 'src/hooks';
import {
  ClientServicesLink,
  MTooltip,
  RetirementAccountContributionFormLink,
  RetirementAccountDistributionFormLink,
} from 'src/lib';
import { Heading, Spacing } from 'src/styles';
import { isRetirementAccountFundingAllowed } from 'src/utils';

import { DepositByCheckInstructions } from './components/DepositByCheckInstructions/DepositByCheckInstructions';
import { DepositByWireInstructions } from './components/DepositByWireInstructions/DepositByWireInstructions';

export const RetirementAccountFunding = () => {
  const { account } = useAccountSelector();

  const renderIraContributionInstructions = (type: 'check' | 'wire') => {
    return (
      <>
        <p>I would like to make a:</p>
        <ul>
          <li>
            <p>
              <b>IRA Contribution</b>: complete, sign and return with your payment type.
              <br />
              <RetirementAccountContributionFormLink />
              <br />
              Funds being contributed into or distributed from retirement accounts may entail tax consequences.
              <br />
              Contributions are limited and withdrawals before age 59 1/2 may be subject to a penalty tax.
            </p>
          </li>
        </ul>
        <p>
          All IRA contributions count toward your annual limit.
          <br />
          If you made contributions to another external IRA, you&apos;ll want to factor in those amounts when
          contributing to your My IPO IRA. contribution limits can change annually. Visit the{' '}
          <a
            href='https://www.irs.gov/retirement-plans/retirement-plans-faqs-regarding-iras-distributions-withdrawals'
            target='_blank'
            rel='noreferrer'>
            IRS website
          </a>{' '}
          and consult a tax advisor for your specific situation.
        </p>
        {type === 'wire' ? (
          <>
            <strong> WIRE INSTRUCTIONS</strong>
            <DepositByWireInstructions />{' '}
          </>
        ) : (
          <>
            <strong> CHECK INSTRUCTIONS</strong>
            <DepositByCheckInstructions />
          </>
        )}
      </>
    );
  };

  const renderTransferFundsFromAnotherIraAccount = () => {
    return (
      <>
        <p>I would like to:</p>
        <ul>
          <li>
            <p>
              <b>Transfer funds from another IRA Account</b>: complete, sign and return with a copy of your most recent
              IRA statement from the financial firm you would like to transfer funds.
              <br />
              <a
                href='https://legacy.tradingblock.com/Admin/Forms/Apex/Account_Transfer/ACAT_Transfer.pdf'
                target='_blank'
                rel='noreferrer'>
                ACAT Form
              </a>
              <br /> A transfer is a non-reportable movement of funds between two retirement accounts of the same type,
              such as transferring money from one Traditional IRA into another Traditional IRA. This type of transfer
              doesn&apos;t generate a tax form and isn&apos;t reported to the IRS.
            </p>
          </li>
          <li>
            <p>
              <b>Example:</b> If you transfer a Traditional IRA with XYZ Financial to your Traditional IRA at My IPO.
            </p>
          </li>
        </ul>
      </>
    );
  };

  const renderIraWithdrawalInstructions = () => {
    return (
      <>
        <p>I would like to make a:</p>
        <ul>
          <li>
            <p>
              <b>IRA Withdrawal</b>: complete, sign and return with your payment type.
              <br />
              <RetirementAccountDistributionFormLink />
              <br />
              Funds being contributed into or distributed from retirement accounts may entail tax consequences.
              Contributions are limited and withdrawals before age 59 1/2 may be subject to a penalty tax.
            </p>
          </li>
        </ul>
        <p>
          Contents provided are for informational purposes only and don&apos;t constitute tax or investment advice. For
          specific questions, you should consult a tax professional. My IPO does not provide tax advice. Links to third
          party sites are being provided for informational purposes only.
        </p>
      </>
    );
  };

  return (
    <Row>
      <Col span={24}>
        <p className={Heading.subtitle2}>
          Please select one of the following to find more information regarding depositing or withdrawing funds from
          your account, then download appropriate form and submit with required information below to{' '}
          <ClientServicesLink />.
        </p>
      </Col>
      <Col span={24}>
        <Row className={cx(Heading.title2, Spacing.mt16, Spacing.mb8)}>DEPOSIT FUNDS</Row>
        <Row gutter={[0, 4]}>
          {isRetirementAccountFundingAllowed(account?.retirementAccountType?.value) && (
            <Col span={24}>
              <MTooltip type='modal' label='IRA Contribution Wire' title='IRA Contribution Wire' icon='info'>
                {renderIraContributionInstructions('wire')}
              </MTooltip>
            </Col>
          )}
          {isRetirementAccountFundingAllowed(account?.retirementAccountType?.value) && (
            <Col span={24}>
              <MTooltip type='modal' label='IRA Contribution Check' title='IRA Contribution Check' icon='info'>
                {renderIraContributionInstructions('check')}
              </MTooltip>
            </Col>
          )}
          <Col span={24}>
            <MTooltip
              type='modal'
              label='Transfer funds from another IRA Account'
              title='Transfer funds from another IRA Account'
              icon='info'>
              {renderTransferFundsFromAnotherIraAccount()}
            </MTooltip>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row className={cx(Heading.title2, Spacing.mt16, Spacing.mb8)}> WITHDRAWAL FUNDS</Row>
        <Row>
          <Col span={24}>
            <MTooltip type='modal' label='IRA Withdrawal (Check/Wire)' title='IRA Withdrawal (Check/Wire)' icon='info'>
              {renderIraWithdrawalInstructions()}
            </MTooltip>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
