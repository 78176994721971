import { AnyAction } from 'redux';

import { OnboardStep } from '../models/app.models';

import { createAction, Type } from './utils';

export const doAddRedirectToOffer = (offerId: string): AnyAction => createAction(Type.ADD_REDIRECT_TO_OFFER, offerId);

export const doRemoveRedirectToOffer = (): AnyAction => createAction(Type.REMOVE_REDIRECT_TO_OFFER);

export const doAddRedirectToOfferOrder = (offerId: string): AnyAction =>
  createAction(Type.ADD_REDIRECT_TO_OFFER_ORDER, offerId);

export const doRemoveRedirectToOfferOrder = (): AnyAction => createAction(Type.REMOVE_REDIRECT_TO_OFFER_ORDER);

export const doAddRedirectToEmailConfirm = (payload: { confirm: string; updateId: number }): AnyAction =>
  createAction(Type.APP_REDIRECT_TO_EMAIL_CONFIRM_ADD, payload);

export const doRemoveRedirectToEmailConfirm = (): AnyAction => createAction(Type.APP_REDIRECT_TO_EMAIL_CONFIRM_REMOVE);

export const updateOnboardStep = (payload: OnboardStep): AnyAction => createAction(Type.UPDATE_ONBOARD_STEP, payload);

export const doAddOfferInvestReferrer = (payload: { id: string; name: string; logoUrl?: string }): AnyAction =>
  createAction(Type.ADD_OFFER_INVEST_REFERRER, payload);

export const doRemoveOfferInvestReferrer = (): AnyAction => createAction(Type.REMOVE_OFFER_INVEST_REFERRER);
